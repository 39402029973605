.registerForm{overflow: hidden;width: 1024px;margin: 0 auto;}
.registerForm .bloq{float:left;width:100%;margin-left: 70px;}
.registerForm .bloq.parallell{float:left;width:40%;margin-left: 70px;}
.registerForm span{margin-left: 190px;font-size: 35px;margin-top: 20px;float: left;color: #fff;text-shadow: 0 0 7px #000;}
.registerForm .facebook{ background: url('../../static/img/button/boton_fb_off.png') no-repeat; height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 25px;}
.registerForm .twitter{background: url('../../static/img/button/boton_tw_off.png');background-repeat: no-repeat;height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 30px;}
.registerForm .google{background: url('../../static/img/button/boton_g_off.png');background-repeat: no-repeat;height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 30px;}
.registerForm .facebook:hover{background: url('../../static/img/button/boton_fb_on.png');background-repeat:no-repeat;}
.registerForm .twitter:hover{background: url('../../static/img/button/boton_tw_on.png');background-repeat:no-repeat;}
.registerForm .google:hover{background: url('../../static/img/button/boton_g_on.png');background-repeat:no-repeat;}
.registerForm .textInput{float: left;width: 85%;margin-top: 5px;}
.registerForm .textInput div{float: left;width: 100%;margin-top: 10px;}
.registerForm .textInput label{width: auto;float: left;font-size: 20px;background-color: #fff;color: #999;line-height: 70px;padding-left: 10px;padding-right: 20px;}
.registerForm .textInput input{float: left;height: 68px;color: #000 !important;font-size: 20px;width: 30px;box-shadow: 0 0 0px #000 inset;border: 0;}
.registerForm .textInput input.uno{width:84.7%;}
.registerForm .textInput input.dos{width:81.5%;}
.registerForm .textInput input.tres{width:66.4%;height: 70px;}
.registerForm .textInput input.cuatro{width:67.9%;}
.registerForm .textInput input.cinco{width:88.1%;}
.registerForm .textInput input.seis{width:66.0%;}
.registerForm .textInput input.siete{width:67.9%;}
.registerForm .textInput input#input-terms { height: 25px; }
.registerForm .register{text-shadow: 0 0 4px #000;background: url('../../static/img/button/boton_entrar_off.png');float: left;height: 56px;width: 375px;font-size: 30px;color: #fff;text-decoration: none;padding-top: 25px;margin-top: 25px;margin-right: 25px;text-align: center;    margin-left: 100px;}
.registerForm .register:hover{background: url('../../static/img/button/boton_entrar_pulsado.png');}
.registerForm .cancel{text-shadow: 0 0 4px #000;background: url('../../static/img/button/boton_cancel.png');float: left;height: 56px;width: 375px;font-size: 30px;color: #fff;text-decoration: none;padding-top: 25px;margin-top: 25px;margin-right: 25px;text-align: center;}
.registerForm .cancel:hover{background: url('../../static/img/button/boton_entrar_pulsado.png');}
.registerForm .noAccount{font-size: 17px;color: #fff;text-shadow: 0 0 3px #000;text-decoration: none;margin: 7px 0 0 8px; float: left;text-align: left;letter-spacing: 1px;}

.floatContainerFases {background: url('../../static/img/backgrounds/fondo_popup.png');width: 645px;margin-top: -30px;margin-left: 265px;position: absolute;z-index: 10;border-radius: 10px;padding-bottom: 30px;height:445px;}
.floatContainerFases a.close {background: url('../../static/img/button/bt-cerrar.png');text-indent: -5000px;width: 45px;height: 45px;color: transparent;display: block;float: right;margin-right: 30px;margin-top: 15px;cursor: pointer;}
.floatContainerFases .title{float:left;font-size: 50px;background: -webkit-linear-gradient(#d1e32b, #9daa20);-webkit-background-clip: text;-webkit-text-fill-color: transparent;font-weight: bold;margin-top: 25px;margin-bottom: 25px;font-size: 33px !important;margin-left: 39px !important;width: 420px !important;}
.floatContainerFases ul {overflow: hidden;width: 430px;height: 336px;box-shadow: inset -13px 4px 22px #000;overflow-y: auto;padding: 0;padding-right: 20px;margin-left: 40px;list-style:none;}
.floatContainerFases ul li{float: left;width: 100%;overflow: hidden;border-bottom: 1px solid #8e8e8e;font-size: 23px;line-height: 55px;}
.floatContainerFases ul li .fase{float:left;color:#adc42f;width: 160px;margin-left:10px;}
.floatContainerFases ul li .fecha{float:left;color:#616161;    width: 160px;}
.floatContainerFases ul li .hour{float:left;color:#dad9d9;}
.floatContainerFases.terminos.ext {width: 1200px;margin: 0 20%;height: 635px;background: url('../../static/img/backgrounds/bg-popup-big.png');}
.floatContainerFases.terminos {width: 1200px;
    height: 635px;
    background: rgba(0,0,0,0.85);
    position: fixed;
    left: 50%;
    margin-left: -600px;
    top: 50%;
    margin-top: -317px;}
.floatContainerFases.terminos .title { width: 800px !important;text-align: left;font-size: 32px !important;margin-top: 20px;}
.floatContainerFases.terminos .bloque{text-align: left;color: #fff;text-shadow: 0 0 3px #000;float: left;font-size: 20px;margin: 0;margin-bottom: 10px;font-weight: 100;line-height: 25px;}
.floatContainerFases.terminos .bloque{overflow-y: auto;height: 580px;width: 1100px;margin-left: 50px;}
.floatContainerFases.terminos ul {overflow: hidden;width: 460px;height: 336px;box-shadow: inset 0px 0px 0px #000;overflow-y: auto;padding: 0;padding-right: 20px;margin-left: 360px;list-style: none;margin-top: 30px;float: left;}
.floatContainerFases.terminos ul a{color:#999;text-decoration:none;}
.floatContainerFases.terminos ul li.current {background: #8db53a; background: -moz-linear-gradient(top, #8db53a 0%, #6a882c 50%, #475c1d 100%); /* FF3.6-15 */background: -webkit-linear-gradient(top, #8db53a 0%,#6a882c 50%,#475c1d 100%); /* Chrome10-25,Safari5.1-6 */background: linear-gradient(to bottom, #8db53a 0%,#6a882c 50%,#475c1d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8db53a', endColorstr='#475c1d',GradientType=0 ); /* IE6-9 */}
.floatContainerFases.terminos ul li:hover {background: #8db53a; background: -moz-linear-gradient(top, #8db53a 0%, #6a882c 50%, #475c1d 100%); /* FF3.6-15 */background: -webkit-linear-gradient(top, #8db53a 0%,#6a882c 50%,#475c1d 100%); /* Chrome10-25,Safari5.1-6 */background: linear-gradient(to bottom, #8db53a 0%,#6a882c 50%,#475c1d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8db53a', endColorstr='#475c1d',GradientType=0 ); /* IE6-9 */}
.floatContainerFases.terminos ul li.current a{color:#fff;margin-left: 15px;text-shadow:0 0 4px #000;}
.floatContainerFases.terminos ul li:hover a{color:#fff;margin-left: 15px;text-shadow:0 0 4px #000;}
.floatContainerFases.terminos button.seleccionar {background: rgba(140,181,58,1);background: -moz-linear-gradient(top, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%);background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(140,181,58,1)), color-stop(50%, rgba(105,136,44,1)), color-stop(100%, rgba(71,92,29,1)));background: -webkit-linear-gradient(top, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%);background: -o-linear-gradient(top, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%);background: -ms-linear-gradient(top, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%);background: linear-gradient(to bottom, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8cb53a', endColorstr='#475c1d', GradientType=0 );width: 400px;margin-left: 390px;float: left;text-shadow: 0 0 4px #000;letter-spacing: 1px;height: 45px;font-size: 19px;border: 0;color: #fff;margin-top: 12px;cursor: pointer;}

.help-block{color: red; clear: both;}