
.spinner-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.spinner-center-loading {
  background-color: rgba(0,0,0,0.7); 
  width: 100%; 
  height: 100%; 
  position: absolute; 
  top: 0; 
  left: 0; 
  z-index: 15; 
  text-align: center; 
  padding-top: 5%
}