/* LOGIN */
.loginForm{overflow: hidden;width: 1024px;margin: 0 auto;}
.boxShadow{background: url('../../static/img/backgrounds/trama.png');overflow: hidden;width: 986px;padding-bottom: 25px;}
.loginForm .bloq{float:left;width:100%;margin-left: 70px;}
.loginForm span{margin-left: 190px;font-size: 35px;margin-top: 30px;float: left;color: #fff;text-shadow: 0 0 7px #000;}
.loginForm .facebook{background: url('../../static/img/button/boton_fb_off.png');background-repeat: no-repeat;height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 25px;}
.loginForm .twitter{background: url('../../static/img/button/boton_tw_off.png');background-repeat: no-repeat;height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 30px;}
.loginForm .google{background: url('../../static/img/button/boton_g_off.png');background-repeat: no-repeat;height: 101px;float: left;width: 860px;cursor:pointer;    margin-top: 30px;}
.loginForm .facebook:hover{background: url('../../static/img/button/boton_fb_on.png');background-repeat:no-repeat;}
.loginForm .twitter:hover{background: url('../../static/img/button/boton_tw_on.png');background-repeat:no-repeat;}
.loginForm .google:hover{background: url('../../static/img/button/boton_g_on.png');background-repeat:no-repeat;}
.loginForm .textInput{float: left;width: 653px;margin-top: 10px;}
.loginForm .textInput div{float: left;width: 100%;margin-top: 20px;}
.loginForm .textInput label{width: 230px;float: left;font-size: 30px;background-color: #fff;color: #999;line-height: 100px;padding-left: 40px;}
.loginForm .textInput input{float: left;height: 98px;color: #000 !important;font-size: 20px;width: 355px;box-shadow: 0 0 0px #000 inset;border: 0;}
.loginForm .enter{    text-shadow: 0 0 4px #000; background: url('../../static/img/button/boton_entrar_off.png');background-repeat: no-repeat;float: left;height: 76px;width: 153px;font-size: 38px;color: #fff;text-decoration: none;padding-top: 25px;padding-left: 52px;margin-top: 80px;}
.loginForm .enter:hover{background: url('../../static/img/button/boton_entrar_pulsado.png');}
.loginForm .noAccount{font-size: 25px;color: #fff;text-shadow: 0 0 3px #000;text-decoration:none;margin-top:25px;float:left;width:90%;text-align:center;letter-spacing:0.5;letter-spacing: 1px;}
								
.loginForm.recuperar span{color:#88a439;}
.loginForm.recuperar .textInput input{float: left;height: 98px;color: #000 !important;font-size: 30px;width: 740px;box-shadow: 0 0 0px #000 inset;border: 0;padding-left: 20px;margin-left: 40px;}
.loginForm.recuperar .enter {text-shadow: 0 0 4px #000;background: url('../../static/img/button/boton_entrar_off.png');float: left;height: 60px;font-size: 32px;color: #fff;text-decoration: none;padding-top: 18px;padding-left: 30px;margin-top: 60px;background-repeat: repeat;padding-right: 30px;width: auto;margin-left: 160px;margin-left: 190px;}
.loginForm.recuperar.email .enter {margin-left: 220px;}

.popUpTerminos {background: url('../../static/img/backgrounds/fondo_popup.png'); z-index: 10;border-radius: 10px;padding-bottom: 30px; width: 1200px;  height: 635px;  background: rgba(0,0,0,0.85); position: fixed; left: 50%; margin-left: -600px; top: 50%;    margin-top: -317px;}
.popUpTerminos a.close {background: url('../../static/img/button/bt-cerrar.png');text-indent: -5000px;width: 45px;height: 45px;color: transparent;display: block;float: right;margin-right: 30px;margin-top: 15px;cursor: pointer;}
.popUpTerminos .title {float:left;font-size: 50px;background: -webkit-linear-gradient(#d1e32b, #9daa20);-webkit-background-clip: text;-webkit-text-fill-color: transparent;font-weight: bold;margin-top: 25px;margin-bottom: 25px;margin-left: 39px !important;text-align: left;}

.popUpTerminos .bloque1{ margin-left: 50px; margin-bottom: 20px; text-align: left;color: #fff;text-shadow: 0 0 3px #000;float: left;font-size: 20px; font-weight: 100;line-height: 25px;width: 90%;  }
.popUpTerminos .bloque2{ margin-left: 50px; margin-bottom: 15px; text-align: left;color: #fff;text-shadow: 0 0 3px #000;float: left;font-size: 20px; font-weight: 100;line-height: 25px; overflow-y: auto;height: 56%;width: 90%; }
.popUpTerminos .btns   {text-align: center;color: #fff;text-shadow: 0 0 3px #000;float: left;font-size: 20px; font-weight: 100;line-height: 25px; width: 100%}

.red{background: linear-gradient(to bottom, rgba(188,0,31,1) 0%, rgba(153,0,26,1) 50%, rgba(124,0,21,1) 100%);}
.green{background: linear-gradient(to bottom, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%)}
