.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* === COMUNES ================================================================================ */
@font-face{
  font-family: 'BreeSerifIE';
  src: url('https://play.mastermus.es/fonts/BreeSerif-Regular.ttf') format('ttf');
 }
 @font-face{
  font-family: 'BreeSerif';
  src: url('https://play.mastermus.es/fonts/BreeSerif-Regular.eot') format('eot');
 }

.logotipo{
    background: url('./static/img/logo/marca.png');
    background-repeat: no-repeat;
    height: 190px;
    text-indent: -6000px;
    width: 335px;
    margin-left: 325px;
}

.bg-tapete{
  background: url(./static/img/backgrounds/fondo.jpg) no-repeat center center fixed;
 -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* ===  GENERIC  ================================================================================ */
.bg-white{background-color:#ebedec;}
.bg-dark {box-shadow: inset 0px 0px 200px #000;background-color:transparent;}
.bg-grey {box-shadow: inset 0px 0px  10px #000;background-color:#28292b; overflow:hidden;}

.section-tittle { color: #7c9f34; font-size: 35px; padding: 15px; margin-left: 65px;font-family: 'Bree Serif', serif;}
.section{ overflow:hidden;   height:530px;}
.submenu{ margin: 0px 10px 0px 10px; }

.subsection{overflow:hidden;height: 488px;border-radius: 0 0 5px 5px;margin-left: 10px;margin-right: 10px; }

.help-block{color: #FF0000; font-weight: bold; font-size: 20px;margin-right: 55px;float:right}
.hidden{display: none}
/* 
.text-green{
    color: #8eaa2b!important;
    text-shadow: 0 0 5px #000!important;
}

.text-red{
    color: #bc2e37;
}

.red-border{
    border: solid 3px red;
    background-color: #768f19;
} */

.green-border{
    border: 3px solid #a6c23a;
    background-color: #768f19;
}

.btn-green{ 
  margin:1px; 
  background: linear-gradient(to bottom, rgba(140,181,58,1) 0%, rgba(105,136,44,1) 50%, rgba(71,92,29,1) 100%); 
  font-size: 20px; 
  color: #fff; 
  border: 0px; 
  padding: 10px 35px; 
  font-family: 'myriad-pro', sans-serif; 
  letter-spacing: 1px; 
  text-shadow: 0 0 4px #000; 
  cursor: pointer; 
  max-width: 180px; }

.btn-red{
  margin:1px;
  background: linear-gradient(to bottom, rgba(188,0,31,1) 0%, rgba(153,0,26,1) 50%, rgba(124,0,21,1) 100%);
  font-size: 25px;
  color: #fff;
  border: 0px;
  padding: 10px 35px;
  font-family: 'myriad-pro', sans-serif;
  letter-spacing: 1px;
  text-shadow: 0 0 4px #000;
  cursor: pointer;
  max-width: 180px;
}

.btn-block{
  max-width: 100%!important;
  font-size: 20px;
}

.btn-disabled{
  text-shadow: 0 0 4px #000;
  background: linear-gradient(to bottom, rgba(110,110,110,1) 0%, rgba(82,82,82,1) 50%, rgba(55,55,55,1) 100%);
  opacity: 0.6;
}

.btn-terms{
  margin:0 5px;
  font-size: 25px;
  color: #fff;
  border: 0px;
  padding: 10px 35px;
  font-family: 'myriad-pro', sans-serif;
  letter-spacing: 1px;
  text-shadow: 0 0 4px #000;
  cursor: pointer;
  width: 210px;
}