.bt-home {
    background: rgba(122, 185, 40, 1);
    background: -moz-linear-gradient(top, rgba(122, 185, 40, 1) 0%, rgba(77, 119, 23, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(122, 185, 40, 1)), color-stop(100%, rgba(77, 119, 23, 1)));
    background: -webkit-linear-gradient(top, rgba(122, 185, 40, 1) 0%, rgba(77, 119, 23, 1) 100%);
    background: -o-linear-gradient(top, rgba(122, 185, 40, 1) 0%, rgba(77, 119, 23, 1) 100%);
    background: -ms-linear-gradient(top, rgba(122, 185, 40, 1) 0%, rgba(77, 119, 23, 1) 100%);
    background: linear-gradient(to bottom, rgba(122, 185, 40, 1) 0%, rgba(77, 119, 23, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7ab928', endColorstr='#4d7717', GradientType=0);
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 8px #000;
    width: 330px;
    height: 75px;
    text-align: center;
    display: block;
    font-size: 35px;
    padding-top: 30px;
    margin: 30px auto;
}

.centered-section {
    overflow: hidden;
    width: 1024px;
    margin: 0 auto;
}

.text-centered{
    text-align: center;
}

h1{
    font-size: 50px;
    color: #fff;
    text-shadow: 0 0 7px #000;
    width: 100%;
}

h2 {
    font-size: 75px;
    text-shadow: 0 0 7px #000;
    color: #fff;
    margin-top: 10px;
}